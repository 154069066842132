import { t } from "i18next"
import { ChangeEvent, useState } from "react"
import { useParams } from "../../helper/Helper"
import { useStore } from "../../contexts/store"
import api from "../../api/api"
import { UserInfo } from "../../../../types/User"
import { userPwMaxLength, userPwMinLength, notUserPwRegex } from "../../validationConstantsString"
import { alert }from "../../stores/alertStore"

import { Alert, Box, Button, FormControl, InputAdornment, InputLabel, OutlinedInput } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'


const ChangeMyPassword = ({handleClose, user}: {handleClose: () => void, user: UserInfo}) => {
  const { podId } = useParams()
  const { alertStore, uiStore } = useStore()
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [confirmNewPassword, setConfirmNewPassword] = useState("")
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const deviceWidth = uiStore.deviceWidth

  const saveNewPassword = async () => {
    setIsLoading(true)
    const res = await api.changePassword(podId, user.userId, newPassword, oldPassword)
    if (res && res.status === 200) {
      setSuccess(true)
    }
    else {
      alertStore.push(alert(JSON.stringify(res.body?.msg), 'error'))
    }
    setIsLoading(false)
  }

  const handleNewPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value
    setNewPassword(value)
    const isValid = isValidPassword(value)
    setHasError(!isValid)
  }

  const isValidPassword = (password: string) => {
    if(password === "") return true
    // check password length
    if(password.length < userPwMinLength) return false
    if(password.length > userPwMaxLength) return false
    // check if characters are valid
    const hasValidCharacters = password.match(notUserPwRegex) ? false : true
    return hasValidCharacters
  }

  const isValidConfirmPassword = (password: string) => {
    if(newPassword === password || password === "") return true
    return false
  }

  return (
    <Box sx={{display: "grid", overflow: "auto", gridTemplateRows: "min-content auto min-content", height: "100%", margin: "0 20px"}}>
      <Box sx={{margin: "20px 0 10px 0", padding: "10px 20px", color: "grey"}}>
        {t('changeMyPassword_header')}
      </Box>
      <Box sx={{display: "grid", alignContent: "start", padding: "0 10px"}}>
        {/* old password */}
        <FormControl sx={{margin: "10px 0"}}>
          <InputLabel htmlFor="user-old-password">{t('Old password')}</InputLabel>
          <OutlinedInput
            disabled={success ? true : false}
            id={'user-old-password'}
            type="password"
            label={t('Old password')}
            value={oldPassword}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {setOldPassword(event.target.value)}}
          />
        </FormControl>
        {/* new password */}
        <FormControl sx={{margin: "10px 0"}}>
          <InputLabel htmlFor="user-new-password">{t('New password')}</InputLabel>
          <OutlinedInput
            disabled={success ? true : false}
            error={!isValidPassword(newPassword)}
            id={'user-new-password'}
            type="password"
            label={t('New password')}
            value={newPassword}
            onChange={handleNewPasswordChange}
            endAdornment={
              <InputAdornment position="end">
                {(isValidPassword(newPassword) && newPassword) &&
                  <CheckIcon color="success" />
                }
              </InputAdornment>
            }
          />
        </FormControl>
        {/* confirm new password */}
        <FormControl sx={{margin: "10px 0"}}>
          <InputLabel htmlFor="user-confirm-new-password">{t('Confirm new password')}</InputLabel>
          <OutlinedInput
            disabled={success ? true : false}
            error={!isValidConfirmPassword(confirmNewPassword)}
            id={'user-confirm-new-password'}
            type="password"
            label={t('Confirm new password')}
            value={confirmNewPassword}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {setConfirmNewPassword(event.target.value)}}
            endAdornment={
              <InputAdornment position="end">
                {(isValidConfirmPassword(confirmNewPassword) && confirmNewPassword) &&
                  <CheckIcon color="success" />
                }
              </InputAdornment>
            }
          />
        </FormControl>
      </Box>
      <Box sx={{ display: "grid", alignSelf: "end", margin: "20px 0px" }}>
        {hasError &&
          <Box sx={{padding: "10px"}}>
              <Alert severity="error">{t('The password must contain at least {{minLength}} and no more than {{maxLength}} characters. It must not contain any special characters or spaces.', {minLength: userPwMinLength, maxLength: userPwMaxLength})}</Alert>
          </Box>
        }
        {success &&
          <Box sx={{padding: "10px"}}>
            <Alert severity="success">{t('Password changed successfully.')}</Alert>
          </Box>
        }
        {!success &&
          <Box sx={{display: "flex", gridGap: "10px", flexWrap: "wrap", justifyContent: "end", margin: "10px"}}>
            <Button onClick={handleClose} variant="outlined" sx={{backgroundColor: "white", flex: (deviceWidth === "tiny") ? 1 : ""}}>
              {t('Cancel')}
            </Button>
            <Button disabled={!(isValidPassword(newPassword) && isValidConfirmPassword(confirmNewPassword) && newPassword && confirmNewPassword && !isLoading)} onClick={saveNewPassword} variant="contained" sx={{ flex: (deviceWidth === "tiny") ? 1 : "" }}>
              {t('Save')}
            </Button>
          </Box>
          }
          {success &&
          <Box sx={{display: "flex", gridGap: "10px", flexWrap: "wrap", justifyContent: "end", margin: "10px"}}>
            <Button onClick={handleClose} variant="outlined" sx={{backgroundColor: "white", flex: (deviceWidth === "tiny") ? 1 : ""}}>
              {t('Close')}
            </Button>
           </Box>
          }
      </Box>
    </Box>
  )
}

export default ChangeMyPassword