import i18next from 'i18next'
import { makeObservable, observable, action } from 'mobx'
import exportSessionStore from './sessionStore'
import api from '../api/api'
import { env } from '../api/api'
import {
  PDFDocument,
  PDFName,
  PDFDict,
  PDFArray,
  PDFStream,
  decodePDFRawStream,
  PDFHexString,
  PDFString,
  PDFRawStream,
} from 'pdf-lib'
import { richPdfData } from '../../../types/ShrimpRichPdf'
import { pdfFileNameMaxLength } from '../validationConstantsString'


export interface pdfImportStoreModel {
  queue:PdfImportFileInfo[],
  addToQueue: (file:PdfImportFileInfo) => void,
  clear: () => void,
  queueModFile: (i:number, file:ArrayBuffer|null) => void,
  queueModDoImport: (i:number, doImport:boolean) => void,
  queueModSkipInteractions: (i:number, skipInteractions: object) => void,
  queueModOverrideName: (i:number, overrideName:string) => void,
  uuidResolver: (uuid:string, attr?:{[key:string]:string|boolean|number}) => { newUuid:string, attr:{[key:string]:string|boolean|number} },
  uuidMap: {[uuid:string]: { newUuid:string, attr:{[key:string]:string|boolean|number} }}
}

export type PdfImportFileInfo = {
  file:ArrayBuffer|null,
  originalFileName:string,
  originalRawFile?:ArrayBuffer,
  overrideName?:string,
  shrimpData: richPdfData|null,
  status: 'pending'|'processing'|'parsed',
  doImport?: undefined|boolean,
  skipInteractions?: undefined|Object,
}

function extractRawAttachments (pdfDoc: PDFDocument) {
  if (!pdfDoc.catalog.has(PDFName.of('Names'))) return [];
  const Names = pdfDoc.catalog.lookup(PDFName.of('Names'), PDFDict);

  if (!Names.has(PDFName.of('EmbeddedFiles'))) return [];
  const EmbeddedFiles = Names.lookup(PDFName.of('EmbeddedFiles'), PDFDict);

  if (!EmbeddedFiles.has(PDFName.of('Names'))) return [];
  const EFNames = EmbeddedFiles.lookup(PDFName.of('Names'), PDFArray);

  const rawAttachments = [];
  for (let idx = 0, len = EFNames.size(); idx < len; idx += 2) {
    const fileName = EFNames.lookup(idx) as PDFHexString | PDFString;
    const fileSpec = EFNames.lookup(idx + 1, PDFDict);
    rawAttachments.push({ fileName, fileSpec });
  }

  return rawAttachments;
}

function getShrimpData(pdfDoc: PDFDocument) {
  const rawAttachments = extractRawAttachments(pdfDoc);
  const shrimpAttachments = rawAttachments
    .map(({ fileName, fileSpec }) => {
      const stream = fileSpec
        .lookup(PDFName.of('EF'), PDFDict)
        .lookup(PDFName.of('F'), PDFStream) as PDFRawStream
      return {
        name: fileName.decodeText(),
        data: decodePDFRawStream(stream).decode(),
      };
    })
    .filter(attachment => attachment.name === 'Shrimp')
    if (shrimpAttachments.length === 1) {
      const string = new TextDecoder().decode(shrimpAttachments[0].data)
      const shrimpData:richPdfData = JSON.parse(string)
      return shrimpData
    }
    else {
      console.warn(`Found ${shrimpAttachments.length} Shrimp attachments`)
      return null
    }
}

const processPdf = async (buffer:ArrayBuffer|null) => {
  if (!buffer) return {
    file:null,
    shrimpData:null,
  }

  try {
    const pdfDoc = await PDFDocument.load(buffer)

    const shrimpData:null|richPdfData = getShrimpData(pdfDoc)

    // Strip all embeddings
    if (pdfDoc.catalog.has(PDFName.of('Names'))) {
      const Names = pdfDoc.catalog.lookup(PDFName.of('Names'), PDFDict);
      if (Names.has(PDFName.of('EmbeddedFiles'))) {
        Names.delete(PDFName.of('EmbeddedFiles'))
      }
    }

    return {
      file: await pdfDoc.save(),
      shrimpData
    }
  }
  catch(e) {
    console.error(e)
    return {
      file:null,
      shrimpData:null,
    }
  }
}


class pdfImportStore {

  queue:PdfImportFileInfo[] = []
  status: 'idle'|'busy' = 'idle'
  uuidMap:{[uuid:string]: { newUuid:string, attr:{[key:string]:string|boolean|number} }} = {}

  constructor() {
    makeObservable(this, {
      queue: observable,
      uuidMap: observable,

      addToQueue: action,
      shiftFromQueue: action,
      clear: action,
      setStatus: action,
      processQueue: action,
      queueModStatus: action,
      queueModFile: action,
      queueModOriginalRawFile: action,
      queueModShrimpData: action,
      queueModDoImport: action,
      queueModSkipInteractions: action,
      queueModOverrideName: action,
      uuidResolver: action,
    })
  }

  setStatus(status: 'idle'|'busy') {
    this.status = status
  }

  uuidResolver(oldUuid:string, attr?:{[key:string]:string|boolean|number}) {
    // console.log(`Resolve uuid ${oldUuid}`)
    if (typeof this.uuidMap[oldUuid] === 'undefined') this.uuidMap[oldUuid] = {
      newUuid: exportSessionStore.createUuid(),
      attr: {}
    }
    const r = this.uuidMap[oldUuid]
    if (attr) for(var key in attr) this.uuidMap[oldUuid].attr[key] = attr[key]
    return this.uuidMap[oldUuid]
  }

  async processQueue() {
    while(this.queue[this.queue.length-1] && (this.queue[this.queue.length-1].status === 'pending')) {
      this.setStatus('busy')
      const firstUnprocessed = this.queue.findIndex(r => r.status === 'pending')
      //console.log(`Process #${firstUnprocessed}`)
      this.queueModStatus(firstUnprocessed, 'processing')
      const { file, shrimpData } = await processPdf(this.queue[firstUnprocessed].file)

      //@todo: set shortened overrideName if necessary

      if (file) {
        //@todo: process shrimp data and set new uuids for linkIds, folderIds, tagIds
        if (shrimpData) {
          console.log(shrimpData)
          for(var interactionId in shrimpData.node.links) {
            const attr:{[which:string]:string} = {}
            attr[shrimpData.node.links[interactionId].which] =interactionId
            const linkData = this.uuidResolver(shrimpData.node.links[interactionId].linkId, {...attr})
            linkData.attr[shrimpData.node.links[interactionId].which] = interactionId
          }
          for(var interactionId in shrimpData.node.taggings) {
            const attr:{[which:string]:string} = {}
            const tagId = shrimpData.node.taggings[interactionId].tagId
            const tag   = shrimpData.context.tags.find(tag => tag.tagId === tagId)
            if (tag?.name) attr.name = tag.name
            if (tag?.description) attr.description = tag.description
            this.uuidResolver(shrimpData.node.taggings[interactionId].tagId, attr)
          }
        }
        this.queueModFile(firstUnprocessed, file)
        this.queueModShrimpData(firstUnprocessed, shrimpData)
        this.queueModDoImport(firstUnprocessed, true)
      }
      else {
        this.queueModOriginalRawFile(firstUnprocessed, this.queue[firstUnprocessed].file)
        this.queueModFile(firstUnprocessed, null)
        this.queueModShrimpData(firstUnprocessed, null)
        this.queueModDoImport(firstUnprocessed, false)
      }
      //console.log('Done processing')
      this.queueModStatus(firstUnprocessed, 'parsed')
      this.setStatus('idle')
    }
  }

  addToQueue(file:PdfImportFileInfo) {
    file.originalFileName = file.originalFileName.substring(0, pdfFileNameMaxLength)
    this.queue.push(file)
    if (this.status === 'idle') this.processQueue()
  }

  shiftFromQueue() {
    const r = this.queue.shift()
    // if the queue is empty now, also empty the uuid map
    if (this.queue.length === 0) Object.keys(this.uuidMap).forEach(uuid => delete this.uuidMap[uuid])
    return r
  }

  queueModStatus(i:number, status:'pending'|'processing'|'parsed') {
    if (this.queue[i]) this.queue[i].status = status
  }

  queueModFile(i:number, file:ArrayBuffer|null) {
    if (this.queue[i]) this.queue[i].file = file
  }

  queueModOriginalRawFile(i:number, file:ArrayBuffer|null) {
    if (file && this.queue[i]) this.queue[i].originalRawFile = file
  }

  queueModShrimpData(i:number, shrimpData:any) {
    if (this.queue[i]) this.queue[i].shrimpData = shrimpData
  }

  queueModDoImport(i:number, doImport:boolean) {
    if (this.queue[i]) this.queue[i].doImport = doImport
  }

  queueModSkipInteractions(i:number, skipInteractions:object) {
    if (this.queue[i]) this.queue[i].skipInteractions = skipInteractions
  }

  queueModOverrideName(i:number, overrideName:string) {
    if (this.queue[i]) this.queue[i].overrideName = overrideName
  }

  clear() {
    this.queue.length = 0
    Object.keys(this.uuidMap).forEach(uuid => delete this.uuidMap[uuid])
  }

}

const exportPdfImportStore = new pdfImportStore()
export default exportPdfImportStore