import React from "react"
import { observer } from "mobx-react-lite"

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { FileUploader } from "react-drag-drop-files";
import dayjs from "dayjs"

import { useStore } from '../../../contexts/store'

import { PdfImportFileInfo } from '../../../stores/pdfImportStore'
import ImportablePdf from "./ImportablePdf"
import { Op, Op_addAnnotation, Op_addComment, Op_addEmotion, Op_addLink, Op_addPdfFile, Op_addTag, Op_addTagging, Op_addWeblink } from "../../../../../types/Ops"
import { Interaction, InteractionTypePlural, iAnnotation, iComment, iEmotion, iLink, iTag, iWeblink } from "../../../../../types/Interaction"
import { alert } from "../../../stores/alertStore"
import { env } from "../../../api/api"

const interactionTypePluralLiterals = ['annotations', 'emotions', 'comments', 'links', 'weblinks', 'taggings', 'readingQuestions'] as const


const ImportDialog = ({  }: {}) => {
  const { podStore, sessionStore, pdfImportStore, opStore, uploadStore, alertStore } = useStore()
  const { t } = useTranslation()

  const pod = podStore.pod
  const session = sessionStore.session

  const clear = () => {
    pdfImportStore.clear()
  }

  const doImport = () => {
    var successfulRun = true
    const tagCreationRegister:{[tagId:string]:boolean} = {}
    pdfImportStore.queue.forEach((file) => {
      if (file.file && file.doImport && pod) {

        const nodeId = sessionStore.createUuid()
        const userInfo = {
          userId: session.user.userId,
          userName: podStore.userPseudonym as string,
          usergroupId: podStore.getUsergroupByRole('Pod').usergroupId,
        }

        const op:Op_addPdfFile = {
          op: 'addPdfFile',
          podId: pod.podId,
          data: {
            nodeId,
            name: file.shrimpData?.node?.name || file.originalFileName || 'unknown file',
            description:'',
            folderId:'',
            userId: sessionStore.session.user.userId,
            usergroupId: podStore.getUsergroupByRole('Pod').usergroupId || '',
            userName: podStore.userPseudonym || '',
            weight: 100 + Object.keys(pod.content.pdfFiles).reduce((acc, nodeId) => { return Math.max(acc, pod.content.pdfFiles[nodeId].weight) }, 0),
            hidden: true,
            size: file.file.byteLength || 0,
            hash: '',
            nofPages:0,
            status: 'scheduled',
            coid:null,
          }
        }

        const importOps:Op[] = []

        if (file.shrimpData) {
          interactionTypePluralLiterals.forEach((interactionType:InteractionTypePlural) => {
            if (file.shrimpData && file.shrimpData.node[interactionType]) {
              {
                Object.keys(file.shrimpData.node[interactionType]).map((interactionId:string) => {
                  if (file.shrimpData) {
                    const interaction:Interaction = file.shrimpData.node[interactionType][interactionId]

                    // update the interaction for a new context
                    interaction.anchor.nodeId = nodeId
                    interaction.interactionId = sessionStore.createUuid()
                    interaction.coid          = null
                    interaction.tCreated      = dayjs().unix()
                    interaction.tModified     = dayjs().unix()
                    // delete interaction.dSeen
                    // delete interaction.tSeen

                    switch(interactionType) {
                      case 'annotations': {
                        const op:Op_addAnnotation = { op:'addAnnotation', podId: pod.podId, data: { ...interaction, ...userInfo } as iAnnotation }
                        importOps.push(op)
                        } break
                      case 'comments': {
                        const op:Op_addComment = { op:'addComment', podId: pod.podId, data: { ...interaction, ...userInfo } as iComment }
                        importOps.push(op)
                        } break
                      case 'emotions': {
                        const op:Op_addEmotion = { op:'addEmotion', podId: pod.podId, data: { ...interaction, ...userInfo } as iEmotion }
                        importOps.push(op)
                        } break
                      case 'links': {
                        const op:Op_addLink = { op:'addLink', podId: pod.podId, data: { ...interaction, ...userInfo } as iLink }
                        if (pdfImportStore.uuidMap[op.data.linkId] && pdfImportStore.uuidMap[op.data.linkId].attr.src && pdfImportStore.uuidMap[op.data.linkId].attr.dst) {
                          op.data.linkId = pdfImportStore.uuidMap[op.data.linkId].newUuid
                          delete op.data.other
                          importOps.push(op)
                        }
                        } break
                      case 'taggings': {
                        const op:Op_addTagging = { op:'addTagging', podId: pod.podId, data: { ...interaction, ...userInfo } as iTag }
                        const { newUuid, attr } = pdfImportStore.uuidMap[op.data.tagId]
                        if (newUuid) {
                          if (!tagCreationRegister[newUuid]) {
                            const addTag:Op_addTag = {
                              op: 'addTag',
                              podId: pod.podId,
                              data: {
                                ...userInfo,
                                tagId: newUuid,
                                name: attr.name as string || 'No name',
                                description: attr.description as string || '',
                                coid:null,
                              }
                            }
                            importOps.push(addTag)
                            tagCreationRegister[newUuid] = true
                          }
                          op.data.tagId = newUuid
                        }
                        } break
                      case 'weblinks': {
                        const op:Op_addWeblink = { op:'addWeblink', podId: pod.podId, data: { ...interaction, ...userInfo } as iWeblink }
                        importOps.push(op)
                        } break
                    }
                  }
                })
              }
          }})
        }

        const success = (nodeId:string, filename:string) => {
          alertStore.push(alert(t(`Uploaded {{filename}}. Applying {{nofOps}} ops`, {filename, nofOps: importOps.length}), 'success'))
          opStore.doOp(op)
          importOps.forEach(importOp => opStore.doOp(JSON.parse(JSON.stringify(importOp))))
        }

        const failure = (filename:string) => {
          alertStore.push(alert(t(`Could not upload {{filename}}. Please try again later.`, {filename}), 'error'))
          successfulRun = false
        }

        uploadStore.add(`${env.backendBaseUrl}/uploadPdf`, pod.podId, { nodeId }, file.file, file.originalFileName, 'application/pdf', (id:string) => success(id, file.originalFileName), () => failure(file.originalFileName))

      }
    })

    if (successfulRun) pdfImportStore.clear()

  }

  const processFileList = (fileList:FileList) => {
    for (var i=0; i<fileList.length; i++) {
      const file = fileList[i]
      const reader = new FileReader()
      reader.onload = async function() {
        const fileData = this.result
        if ((fileData) && (typeof fileData === 'object')) {
          const entry: PdfImportFileInfo = {
            file: fileData,
            originalFileName: file.name,
            shrimpData: null,
            status: 'pending',
          }
          pdfImportStore.addToQueue(entry)
        }
      }
      reader.readAsArrayBuffer(file)
    }
  }

  const uuidResolved:{[oldUuid:string]: string} = {}

  const uuidResolver = (oldUuid:string) => {
    if (!uuidResolved[oldUuid]) uuidResolved[oldUuid] = `resolved-${oldUuid}` //////////////sessionStore.createUuid()
    return uuidResolved[oldUuid]
  }


  return <Dialog open={Boolean(pdfImportStore.queue.length)}>
    <DialogTitle>{t('Import {{nofSelectedFiles}} of {{nofFiles}} Files', {nofSelectedFiles: pdfImportStore.queue.filter((e) => e.doImport).length, nofFiles: pdfImportStore.queue.length})}</DialogTitle>
    <DialogContent>
      { pdfImportStore.queue.map((entry, i) => <ImportablePdf key={i} i={i} />)}
      <FileUploader multiple hoverTitle={t('Drop files here')} handleChange={processFileList} name="file">
        <Typography variant="caption" sx={{width:'100%', borderWidth:'2px', borderStyle:'dashed', borderColor:'primary.main', display:'block', textAlign: 'center', padding: '8px'}}>{t('To add files, drop them here or click')}</Typography>
      </FileUploader>
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" onClick={clear}>{t('Cancel')}</Button>
      <Button variant="contained" onClick={doImport} disabled={pdfImportStore.queue.filter(e => e.status !== 'parsed').length !==0}>{t('Import')}</Button>
    </DialogActions>
  </Dialog>

}

export default observer(ImportDialog)