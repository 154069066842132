import i18next from 'i18next'
import { makeObservable, observable, action } from 'mobx'

import exportSessionStore from './sessionStore'
import api from '../api/api'
import { env } from '../api/api'
import dayjs from 'dayjs'

const timeout = 1000 * 60 * 15

export interface uploadStoreModel {
  uploadQueue:UploadJob[],
  add: (endpoint:string, podId:string, signedUrlAttributes:object, file:ArrayBuffer, filename:string, mimeType:string, successCallback:Function|null, failureCallback:Function|null) => void,
  status: 'idle'|'uploading'|'error',
}

export type UploadJob = {
  file: ArrayBuffer,
  filename: string,
  mimeType:string,
  signedUrl: string,
  rangeCompleted?:number,
  t0?:number,
  successCallback: Function|null,
  failureCallback: Function|null,
}

class uploadStore {

  uploadQueue:UploadJob[] = []
  status:'idle'|'uploading'|'error' = 'idle'

  constructor() {
    makeObservable(this, {
      uploadQueue: observable,
      status: observable,

      addToQueue: action,
      shiftFromQueue: action,
      setStatus: action,
    })
  }

  setStatus(status:'idle'|'uploading'|'error') {
    // console.log(`set uploadStore.status = ${status}`)
    this.status = status
  }

  addToQueue(job:UploadJob) {
    this.uploadQueue.push(job)
  }

  shiftFromQueue() {
    const r = this.uploadQueue.shift()
    return r
  }

  async doUpload() {
    const controller = new AbortController()
    const id = setTimeout(() => controller.abort(`timeout of ${timeout/1000}s`), timeout)
    const chunksize = 256 * 1024 * 2

    while(this.uploadQueue.length) {

      const entry = this.uploadQueue[0]
      if (!entry) {
        this.shiftFromQueue()
        continue
      }

      this.setStatus('uploading')

      // Start processing the entry
      entry.t0             = dayjs().unix()
      entry.rangeCompleted = 0
      const idQuery = await fetch(entry.signedUrl, {
        method: 'POST',
          headers: {
              'x-goog-resumable': 'start',
              'Content-Type': entry.mimeType,
          }
      })

      const uploadId = idQuery.headers.get('x-guploader-uploadid') || ''

      try {
        do {
          const part = entry.file.slice(entry.rangeCompleted, Math.min(entry.rangeCompleted + chunksize, entry.file.byteLength))

          const headers = {
            "Content-Type": entry.mimeType,
            "Content-Range": `bytes ${entry.rangeCompleted}-${entry.rangeCompleted + part.byteLength-1}/${entry.file.byteLength}`,
          }

          const query:Response = await fetch(`${entry.signedUrl}&upload_id=${uploadId}`, {
            method: 'PUT',
            headers,
            body: part,
          })

          if (query.status === 308) {
            const range = query.headers.get('range')
            if (range) {
              const rangePart:number[] = range.replace('bytes=', '').split('-').map((s:string) => parseInt(s))
              entry.rangeCompleted = rangePart[1]+1
            }
          }
          else if ((query.status === 200) || (query.status === 201)) {
            if (query.headers.get('x-goog-stored-content-length')) entry.rangeCompleted = parseInt(query.headers.get('x-goog-stored-content-length')||`${entry.file.byteLength}`)
            break
          }
          else {
            console.warn(query)
            if (entry.failureCallback) entry.failureCallback()
            throw new Error('PUT gave status ' + query.status)
          }
        } while(entry.rangeCompleted && entry.rangeCompleted<entry.file.byteLength-1)

        this.setStatus('idle')
        if (entry.successCallback) entry.successCallback()

      }
      catch (e) {
        this.setStatus('idle')
        console.error(e)
        this.shiftFromQueue()
        if (entry.failureCallback) entry.failureCallback()
      }
      finally {
        clearTimeout(id)
        this.setStatus('idle')
        this.shiftFromQueue()
      }
    } // end while()
  }

  async add(endpoint:string, podId:string, signedUrlAttributes:object, file:ArrayBuffer, filename:string, mimeType:string, successCallback:Function|null = null, failureCallback:Function|null=null) {

    const sessionId = exportSessionStore.session ? exportSessionStore.session.sessionId : null
    const res = await api.fetch(endpoint, {
      method:'POST',
      headers: {
        'X-SHRIMP-ID': sessionId,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        podId,
        mimeType,
        ...signedUrlAttributes,
      })
    })

    const { signedUrl } = res.body ? res.body : { signedUrl: null }

    if (signedUrl) {
      this.addToQueue({
        file,
        filename,
        mimeType,
        signedUrl,
        successCallback,
        failureCallback
      })
      if (this.status !== 'uploading') this.doUpload()
    }
    else {
      if (failureCallback) failureCallback()
    }
  }

}

const exportUploadStore = new uploadStore()
export default exportUploadStore